import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/img/logoLight.png";
import Modal from "react-bootstrap/Modal";
import LoginForm from "./login";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { getLocalStorage, removeLocalStorage } from "../Common/cookie";
import { getCustomer } from "../api/customer";

function LoginComponent(props) {
  const { logindata, pathname, handleShow, mobile, logout } = props;

  const menu = {
    name: "ACCOUNT",
    path: "",
    child: [
      { name: "PROFILE", path: "/profile" },
      { name: "MY LOANS", path: "/loans" },
      // { name: 'MY INSURANCES', path: '/insurances' },
    ],
    active: ["/profile", "/loans", "/insurances"],
  };
  if (logindata) {
    return (
      <li
        className={
          menu.active.includes(pathname) ? "nav-item active" : "nav-item"
        }
        key={menu.name}
      >
        <Link to={menu.path} className="nav-link account-main-menu">
          {menu.name}
        </Link>
        {menu.child?.length > 0 && (
          <ul
            class={!mobile && "dropdown-menu"}
            style={{ width: mobile ? "100%" : "150px" }}
          >
            {menu.child.map((c) => (
              <li class={!mobile && "nav-item"} key={c.name}>
                <Link class={!mobile && "nav-link"} to={c.path}>
                  {c.name}
                </Link>
              </li>
            ))}
            <li class={!mobile && "nav-item"} key="logout" onClick={logout}>
              <Link class={!mobile && "nav-link"} to="#">
                LOGOUT
              </Link>
            </li>
          </ul>
        )}
      </li>
    );
  } else {
    return (
      <li className="d-flex nav-item" key="login">
        <a
          onClick={handleShow}
          className="nav-link main-menu account-padding"
          style={{ cursor: "pointer" }}
        >
          <div className="account-menu m-0">LOGIN</div>
        </a>
      </li>
    );
  }
}

function Menu(props) {
  const { logindata, setLogindata, authRequire } = props;
  const navigate = useNavigate();
  const location = useLocation();

  axios.interceptors.response.use(
    (res) => {
      if (res.status === 401 || res.status === 403) {
        logout();
        handleShow();
      }    
      return res;
    },
    (err) => {
      if (err.response?.status === 401 || err.response?.status === 403) {
        logout();
        handleShow();
      }
      return err;
    }
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const [clogindata, setClogindata] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setDrawerOpen(false);
    setShow(true);
  };

  const menuItems = [
    {
      name: "HOME",
      path: "/",
      child: [],
      active: ["/", "/home"],
    },
    {
      name: "ABOUT",
      path: "/about",
      child: [],
      active: ["/about"],
    },
    {
      name: "LOAN",
      path: "",
      child: [
        { name: "INSTANT LOAN", path: "/instant-loan" },
        {
          name: "INSTANT PERSONAL LOAN",
          path: "",
          child: [
            { name: "INDUSIND PERSONAL LOAN", path: "/instant/indusind" },
            // {
            //   name: "ADITYA BIRLA PERSONAL LOAN",
            //   path: "/instant/aditya-birla",
            // },
            { name: "SMFC PERSONAL LOAN", path: "/instant/smfg" },
            {
              name: "CREDIT VIDYA PERSONAL LOAN",
              path: "/instant/credit-vidya",
            },
            {
              name: "FIBE PERSONAL LOAN",
              path: "/instant/fibe",
            },
            {
              name: "BAJAJ PERSONAL LOAN",
              path: "/instant/bajaj",
            },
            { name: "IIFL BUSINESS LOAN", path: "/instant/iifl" },
            {
              name: "ADITYA BIRLA UDYOG BUSINESS LOAN",
              path: "/instant/aditya-birla-udyog",
            },
          ],
        },
        { name: "BUSINESS LOAN", path: "/business-loan" },
        { name: "EDUCATION LOAN", path: "/education-loan" },
        { name: "MACHINARY LOAN", path: "/machinary-loan" },
        { name: "HOME LOAN", path: "/home-loan" },
        { name: "LOAN AGAINST PROPERTY", path: "/against-property-loan" },
        { name: "PROFESSIONAL LOAN", path: "/professional-loan" },
        { name: "CAR LOAN", path: "/car-loan" },
        { name: "STUDENT LOAN", path: "/student-loan" },
      ],
      active: [
        "/instant-loan",
        "/business-loan",
        "/education-loan",
        "/machinary-loan",
        "/home-loan",
        "/against-property-loan",
        "/professional-loan",
        "/car-loan",
        "/student-loan",
      ],
    },
    {
      name: "INSURANCE",
      path: "",
      child: [
        { name: "LIFE INSURANCE", path: "/life-insurance" },
        { name: "HEALTH INSURANCE", path: "/health-insurance" },
        { name: "CAR INSURANCE", path: "/car-insurance" },
        // { name: 'BIKE INSURANCE', path: '/bike-insurance' },
      ],
      active: [
        "/life-insurance",
        "/health-insurance",
        "/car-insurance",
        "/bike-insurance",
      ],
    },
    {
      name: "CREDIT CARD",
      path: "",
      child: [
        { name: "AXIS BANK CREDIT CARD", path: "/axis-cc" },
        { name: "HDFC CREDIT CARD", path: "/hdfc-cc" },
        { name: "IDFC CREDIT CARD", path: "/idfc-cc" },
        { name: "ICICI CREDIT CARD", path: "/icici-cc" },
        { name: "INDUSIND BANK CREDIT CARD", path: "/indusind-cc" },
        {
          name: "STANDARD CHARTERED CREDIT CARD",
          path: "/standard-chartered-cc",
        },
      ],
      active: ["/axis-cc", "/hdfc-cc", "/icici-cc", "/indusind-cc"],
    },
    // {
    //     name: 'QUICK PAY',
    //     path: '/quick-pay',
    //     child: [],
    //     active: ['/quick-pay']
    // },
    {
      name: "CONTACT",
      path: "/contact",
      child: [],
      active: ["/contact"],
    },
  ];

  const logout = () => {
    removeLocalStorage("rv-user");
    setLogindata(false);
    if (authRequire) {
      navigate("/");
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setClogindata(response.data);
          setLogindata(true);
          checkTokenExpiration(response.data.accessToken);
        }
      }
    };
    asyncFn();
  }, []);

  const checkTokenExpiration = (token) => {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    const currentTime = new Date().getTime();

    const timeUntilExpiration = expirationTime - currentTime;
    // Set timeout to trigger logout when token expires
    setTimeout(logout, timeUntilExpiration);
  };
  function handleLoginDetails(status) {}

  return (
    <>
      <div class="navbar-area">
        {/* Mobile */}
        <div class="pearo-responsive-nav">
          <div class="container">
            <div class="d-flex justify-content-between pearo-responsive-menu">
              <div class="logo">
                <Link to="/">
                  <img src={logo} alt="logo" height={75} width={75} />
                </Link>
              </div>
              <div class="menu-icon" onClick={toggleDrawer}>
                <i class="fas fa-bars"></i>
              </div>
            </div>
          </div>
        </div>

        {drawerOpen && <div className="overlay" onClick={toggleDrawer}></div>}

        <div className={`drawer-menu ${drawerOpen ? "open" : ""}`}>
          <div class="d-flex justify-content-between p-3">
            <div class="logo">
              <Link to="/">
                <img src={logo} alt="logo" height={75} width={75} />
              </Link>
            </div>
            <div class="menu-icon" onClick={toggleDrawer}>
              <i class="far fa-window-close"></i>
            </div>
          </div>
          <ul>
            {menuItems.length > 0 &&
              menuItems.map((menu) => {
                const navClass = menu.active.includes(location.pathname)
                  ? "nav-item active"
                  : "nav-item";
                return (
                  <li className={navClass} key={menu.name}>
                    <Link
                      to={menu.path}
                      onClick={() => setDrawerOpen(false)}
                      className="main-menu"
                    >
                      {menu.name}
                    </Link>
                    {menu.child?.length > 0 && (
                      <ul>
                        {menu.child.map((c) => (
                          <li key={c.name}>
                            <Link
                              to={c.path}
                              onClick={() => setDrawerOpen(false)}
                            >
                              {c.name}
                            </Link>
                            {c.child?.length > 0 && (
                              <ul className="pt-1">
                                {c.child.map((subItem) => (
                                  <li className="pt-0 pr-0 border-bottom-0" key={subItem.name}>
                                    <Link
                                      to={subItem.path}
                                      onClick={() => setDrawerOpen(false)}
                                    >
                                      <span style={{ fontSize: "13px" }}>
                                        {subItem.name}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
            <LoginComponent
              logindata={logindata}
              pathname={location.pathname}
              handleShow={handleShow}
              logout={logout}
              mobile
            />
          </ul>
        </div>

        {/* desktop */}
        <div class="pearo-nav">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <div>
                <Link class="navbar-brand" to="/">
                  <img src={logo} alt="logo" height={85} width={85} />
                </Link>
              </div>

              <div
                className="collapse navbar-collapse mean-menu justify-content-end mx-3"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  {menuItems.length > 0 &&
                    menuItems.map((menu) => {
                      const navClass = menu.active.includes(location.pathname)
                        ? "nav-item active"
                        : "nav-item";
                      return (
                        <li className={navClass} key={menu.name}>
                          <Link to={menu.path} className="nav-link">
                            {menu.name}
                          </Link>
                          {menu.child?.length > 0 && (
                            <ul className="dropdown-menu">
                              {menu.child.map((c) => (
                                <li className="nav-item" key={c.name}>
                                  <Link to={c.path} className="nav-link">
                                    {c.name}
                                  </Link>
                                  {c.child?.length > 0 && (
                                    <ul className="dropdown-menu">
                                      {c.child.map((subItem) => (
                                        <li
                                          className="nav-item"
                                          key={subItem.name}
                                        >
                                          <Link
                                            to={subItem.path}
                                            className="nav-link"
                                          >
                                            {subItem.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  <LoginComponent
                    logindata={logindata}
                    pathname={location.pathname}
                    handleShow={handleShow}
                    logout={logout}
                  />
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* desktop */}
      <div class="pearo-nav my-sticky-header">
        <div className="container">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div>
              <Link class="navbar-brand" to="/">
                <img src={logo} alt="logo" height={75} width={75} />
              </Link>
            </div>
            <div class="menu-icon sticky-menubar" onClick={toggleDrawer}>
              <i class="fas fa-bars"></i>
            </div>

            <div
              class="collapse navbar-collapse mean-menu justify-content-end mx-3"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                {menuItems.length > 0 &&
                  menuItems.map((menu) => {
                    const navClass = menu.active.includes(location.pathname)
                      ? "nav-item active"
                      : "nav-item";
                    return (
                      <li className={navClass} key={menu.name}>
                        <Link to={menu.path} className="nav-link">
                          {menu.name}
                        </Link>
                        {menu.child?.length > 0 && (
                          <ul className="dropdown-menu">
                            {menu.child.map((c) => (
                              <li className="nav-item" key={c.name}>
                                <Link to={c.path} className="nav-link">
                                  {c.name}
                                </Link>
                                {c.child?.length > 0 && (
                                  <ul className="dropdown-menu">
                                    {c.child.map((subItem) => (
                                      <li
                                        className="nav-item"
                                        key={subItem.name}
                                      >
                                        <Link
                                          to={subItem.path}
                                          className="nav-link"
                                        >
                                          {subItem.name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                <LoginComponent
                  logindata={logindata}
                  pathname={location.pathname}
                  handleShow={handleShow}
                  logout={logout}
                />
              </ul>
            </div>
          </nav>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} >
        <div className="modal-border">
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div>
              <img src={logo} alt="" width="100" />
              {/* <div className='text-center login-heading' >Login</div> */}
            </div>
          </div>
          <div className="section d-flex justify-content-center">
            <form style={{ width: "260px" }}>
              <LoginForm
                setShow={(e) => setShow(e)}
                setLogindata={(e) => setLogindata(e)}
                onSubmit={handleLoginDetails}
                message="Login"
              />
            </form>
          </div>
        </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Menu;
