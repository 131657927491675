import { useEffect, useState } from "react";
import logo from "../Assets/img/logoLight.png";
import instant from "../Assets/img/Loan/instant.png";
import interest from "../Assets/img/LoanIcon/interest.png";
import approveGreen from "../Assets/img/LoanIcon/approveGreen.png";
import online2 from "../Assets/img/LoanIcon/online2.png";
import { RWebShare } from "react-web-share";
import { Link, useNavigate } from "react-router-dom";
import { loanData } from "../Components/loanDetailsData";
import { List, TableList } from "../Components/loanDetails";
import { checkInstantLoan } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import Swal from "sweetalert2";

function ApplyNowInstantBanner(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await checkInstantLoan({
          token: checkCookie.accessToken,
          data: {
            customer_id: checkCookie.id,
          },
        });
        if (response.status === 200) {
          if (
            response?.data?.loan &&
            !response.data.areDatesAtLeast30DaysApart
          ) {
            const status = JSON.parse(response.data.loan.status);
            let status_name = "";
            let token = "";
            let id = response.data.loan.id;
            status.map((s) => {              
              if ((!s.added || s.added === null) && status_name === "") {
                status_name = s.name;
                token = s.token;
              }
            });
            if (status_name === "Customer Data Verification") {
              navigate(`/apply-now/Instant/Basics?id=${id}${token ? `&token=${token}` : ""}`, { replace: true });
            } else if (status_name === "Address Verification") {
              navigate(`/apply-now/Instant/address?id=${id}${token ? `&token=${token}` : ""}`, {
                replace: true,
              });
            } else if (status_name === "Occupation Verification") {
              navigate(`/apply-now/Instant/category?id=${id}${token ? `&token=${token}` : ""}`, {
                replace: true,
              });
            } else if (status_name === "Counter Offer") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Your previous loan was applied within 30 days! You can't not apply new instant loan now.",
              });
            } else if (status_name === "e-KYC") {
              navigate(`/apply-now/Instant/offered?id=${id}${token ? `&token=${token}` : ""}`, {
                replace: true,
              });
            } else if (status_name === "Selfie Upload") {
              navigate(`/apply-now/Instant/selfie?id=${id}${token ? `&token=${token}` : ""}`, { replace: true });
            } else if (status_name === "Bank Details Verification") {
              navigate(`/apply-now/Instant/bank?id=${id}${token ? `&token=${token}` : ""}`, { replace: true });
            } else if (status_name === "e-Sign") {
              navigate(`/apply-now/Instant/review?id=${id}${token ? `&token=${token}` : ""}`, { replace: true });
            } else if (status_name === "Disbursement") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Your previous loan was applied within 30 days! You can't not apply new instant loan now.",
              });
            } else if (status_name === "Disbursed") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Your previous loan was approved and applied within 30 days! You can't not apply new instant loan now.",
              });
            } else if (status_name === "Rejected") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Your previous loan was rejected and applied within 30 days! You can't not apply new instant loan now.",
              });
            } else if (status_name === "Upload Bank Statement") {
              navigate(`/apply-now/Instant/bank-statement?id=${id}${token ? `&token=${token}` : ""}`, {
                replace: true,
              });
            }
          }
        }
      }
    };
    asyncFn();
  }, [logindata]);

  function handleBasicsDetails() {
    navigate("/apply-now/Instant/Basics", { replace: true });
  }

  function isArrayOfObjects(arr) {
    return (
      Array.isArray(arr) &&
      arr.every((item) => typeof item === "object" && item !== null)
    );
  }

  return (
    <div
      class="container-fluid"
      style={{ background: "#f2f4f6", height: "100%", minHeight: "100vh" }}
    >
      <div class="container">
        <div className="row pt-2 d-flex justify-content-between">
          <div>
            <Link to="/">
              {" "}
              <img
                src={logo}
                alt="logo"
                height={95}
                width={95}
                style={{ mixBlendMode: "multiply" }}
              />
            </Link>
          </div>
          {/* <div className="d-flex align-items-center"> */}
          {/* <RWebShare
              data={{
                //   text: "Like humans, flamingos make friends for life",
                url: "https://rvcapitalfinance.com/apply-now/Instant",
                title: "RV Capital Finance",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button
                style={{ border: "2px solid #002d5b", borderRadius: "8px" }}
              >
                🔗
              </button>
            </RWebShare> */}
          {/* </div> */}
          {/* <div className="instant-company"> RV Capital Finance</div> */}
        </div>
        <div className="row mt-3">
          {/* <div className="col-lg-3"></div> */}
          <div className="col-lg-6 col-12 mt-45">
            <h4>GET A</h4>
            <h2>
              <b>PERSONAL LOAN</b>
            </h2>
            <h4>
              IN <b>FEW CLICKS</b>
            </h4>
            <div className="d-flex justify-content-between mt-45 mr-3">
              <div className="p-2">
                <center>
                  <img src={interest} alt="interest" height={70} />
                  <h6 className="mt-2">LOW RATE OF INTEREST</h6>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <img src={approveGreen} alt="instant" height={70} />
                  <h6 className="mt-2">INSTANT APPROVAL</h6>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <img src={online2} alt="online" height={70} />
                  <h6 className="mt-2">COMPLETELY ONLINE</h6>
                </center>
              </div>
            </div>
            <div class="d-none d-lg-block">
              <button
                className="default-btn mt-45"
                onClick={handleBasicsDetails}
              >
                Apply Now
              </button>
            </div>
          </div>
          <div
            className="col-lg-6 col-12" // position-fixed
            style={{ right: "15px" }}
          >
            <div className="d-flex justify-content-center">
              <img
                src={instant}
                alt="instant loan"
                style={{ position: "sticky" }}
              />
            </div>
            <div className="instant-summary">
              <div className="row">
                <div className="col">
                  <ul>
                    <li>Attractive Interest Rate</li>
                    <li>No Hidden Charges</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Quick Approvals</li>
                    <li>Zero Pre-payment Charges</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-block d-lg-none">
              <center>
                <button
                  className="default-btn mt-45 mb-30"
                  onClick={handleBasicsDetails}
                >
                  Apply Now
                </button>
              </center>
            </div>
          </div>
        </div>
        <div>
          {loanData.features && loanData.features.length > 0 && (
            <>
              <div class="section-title" style={{ marginTop: "60px" }}>
                Features
              </div>
              {isArrayOfObjects(loanData.features) ? (
                <List listdata={loanData.features} />
              ) : (
                <TableList listdata={loanData.features} />
              )}
            </>
          )}
          {loanData.eligibility && loanData.eligibility.length > 0 && (
            <>
              <div class="section-title">Eligibility</div>
              <TableList listdata={loanData.eligibility} />
            </>
          )}
          {loanData.documents && loanData.documents.length > 0 && (
            <>
              <div class="section-title mt-30">Documents</div>
              <TableList listdata={loanData.documents} />
            </>
          )}
          {loanData.interest && loanData.interest.length > 0 && (
            <>
              <div class="section-title mt-30">Interest rates & Charges</div>
              {isArrayOfObjects(loanData.interest) ? (
                <List listdata={loanData.interest} />
              ) : (
                <TableList listdata={loanData.interest} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default ApplyNowInstantBanner;
